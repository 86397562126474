html, body {
  box-sizing: border-box;
  height: 100%;
  font-size: 16px;
  line-height: 1.5;
  color: $black;
  font-family: "Graphik", arial, helvetica, sans-serif;
}

* {
  box-sizing: border-box;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nowrap {
  white-space: nowrap;
}

a {
  text-decoration: none;
}

:focus {
  outline-offset: 0;
}

:focus:not(:focus-visible) {
  outline: none;
}

:focus-visible {
  outline-offset: 0;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible {
  :focus:not(.focus-visible) {
    outline: none;
  }
}

/*
  Optionally: Define a strong focus indicator for keyboard focus.
  If you choose to skip this step then the browser's default focus
  indicator will be displayed instead.
*/
.js-focus-visible {
  .focus-visible {
    outline-width: 2px;
    outline-offset: 0;
  }
}