.flatpickr-calendar {
  box-shadow: 0 0 5px rgba(0,0,0,.1);
  border-radius: 5px;

  .flatpickr-months {
    padding: .5rem;
    .flatpickr-prev-month,
    .flatpickr-next-month {
      padding: 1rem;
    }
  }

  .flatpickr-day {
    &--fact-date:not(.selected) {
      background-color: $bg-green;
      &:hover {
        background-color: $bg-green;
      }
    }
    &--busy {
      position: relative;
      &::after {
        position: absolute;
        content: '';
        top: .25rem;
        right: .25rem;
        height: .375rem;
        width: .375rem;
        border-radius: 100%;
        background-color: $red;
      }
    }
  }
}
