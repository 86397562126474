@keyframes spinner-linear-rotate {
  0%{ transform: rotate(0) }
  100%{ transform:rotate(360deg) }
}

@keyframes spinner-stroke-rotate {
  0%{stroke-dashoffset:268.60617px;transform:rotate(0)}
  12.5%{stroke-dashoffset:56.54867px;transform:rotate(0)}
  12.5001%{stroke-dashoffset:56.54867px;transform:rotateX(180deg) rotate(72.5deg)}
  25%{stroke-dashoffset:268.60617px;transform:rotateX(180deg) rotate(72.5deg)}
  25.0001%{stroke-dashoffset:268.60617px;transform:rotate(270deg)}
  37.5%{stroke-dashoffset:56.54867px;transform:rotate(270deg)}
  37.5001%{stroke-dashoffset:56.54867px;transform:rotateX(180deg) rotate(161.5deg)}
  50%{stroke-dashoffset:268.60617px;transform:rotateX(180deg) rotate(161.5deg)}
  50.0001%{stroke-dashoffset:268.60617px;transform:rotate(180deg)}
  62.5%{stroke-dashoffset:56.54867px;transform:rotate(180deg)}
  62.5001%{stroke-dashoffset:56.54867px;transform:rotateX(180deg) rotate(251.5deg)}
  75%{stroke-dashoffset:268.60617px;transform:rotateX(180deg) rotate(251.5deg)}
  75.0001%{stroke-dashoffset:268.60617px;transform:rotate(90deg)}
  87.5%{stroke-dashoffset:56.54867px;transform:rotate(90deg)}
  87.5001%{stroke-dashoffset:56.54867px;transform:rotateX(180deg) rotate(341.5deg)}
  100%{stroke-dashoffset:268.60617px;transform:rotateX(180deg) rotate(341.5deg)}
}

.spinner-icon {
  transform: rotate(-90deg);
  transform-origin: center;
  overflow: visible;
  animation: spinner-linear-rotate 2s linear infinite;
  circle {
    stroke-dasharray: 282.7433388230814px;
    stroke-width: 10%;
    stroke: $blue;
    transition: stroke-dashoffset 225ms linear;
    transition-property: stroke;
    transform-origin: center;
    animation: spinner-stroke-rotate 4s cubic-bezier(.35,0,.25,1) infinite;
  }
}
