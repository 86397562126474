$footer-height: 4.5rem;

.modal-opened > .modal {
  display: flex;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 121;
  display: none;
  align-items: center;
  justify-content: center;
  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
  }
  &__dialog {
    background: $white;
    max-width: 640px;
    width: calc(100% - 4rem);
    box-shadow: 0 2px 2px rgba(0,0,0,.24);
    position: relative;
    z-index: 1;
  }
  &__body {
    padding: 1.25rem 2rem;
  }
  &__footer {
    padding: 0 2rem;
    height: $footer-height;
    border-top: 1px solid $border-gray;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn + .btn {
      margin-left: .75rem;
    }
  }
}

.info-modal {
  .modal__dialog {
    max-width: 760px;
  }
  .modal__footer {
    justify-content: center;
    border-top: none;
  }
  .modal__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #000;
  }
  .modal__body {
    color: #6F6F6F;
    p {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
}

.slide-modal {
  align-items: stretch;
  justify-content: flex-end;
  overflow: hidden;
  .modal__dialog {
    height: 100%;
    flex-direction: column;
    box-shadow: -1rem 0px 1.5rem rgba(0, 0, 0, 0.24);
    animation: slide .3s ease-in-out;
  }
  .modal__body {
    height: calc(100% - #{$footer-height});
    overflow: auto;
    padding-bottom: 3rem;
  }
  .modal__footer {
    justify-content: flex-start;
  }
}

@keyframes slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
