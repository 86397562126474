$pik-fonts-path: 'https://pik.ru.cdn.pik-service.ru/site/fonts';

@font-face {
  font-family: 'Graphik';
  src: url('/assets/fonts/GraphikLCG-Regular.woff') format('woff'),
    url('/assets/fonts/GraphikLCG-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('/assets/fonts/GraphikLCG-Medium.woff') format('woff'),
    url('/assets/fonts/GraphikLCG-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('/assets/fonts/GraphikLCG-Semibold.woff') format('woff'),
    url('/assets/fonts/GraphikLCG-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('/assets/fonts/GraphikLCG-Bold.woff') format('woff'),
    url('/assets/fonts/GraphikLCG-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: ProbaPro;
  src: url($pik-fonts-path+'/proba_pro_regular-webfont.eot');
  src: url($pik-fonts-path+'/proba_pro_regular-webfont.woff2') format('woff2'),
    url('/proba_pro_regular-webfont.woff') format('woff'),
    url($pik-fonts-path+'/proba_pro_regular-webfont.ttf') format('truetype');
  font-display: fallback;
}
@font-face {
  font-family: ProbaPro;
  src: url($pik-fonts-path+'/proba_pro_light-webfont.eot');
  src: url($pik-fonts-path+'/proba_pro_light-webfont.eot?#iefix') format('embedded-opentype'),
    url($pik-fonts-path+'/proba_pro_light-webfont.woff2') format('woff2'),
    url($pik-fonts-path+'/proba_pro_light-webfont.woff') format('woff'),
    url($pik-fonts-path+'/proba_pro_light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-display: fallback;
}
@font-face {
  font-family: ProbaPro;
  src: url($pik-fonts-path+'/proba_pro_medium-webfont.eot');
  src: url($pik-fonts-path+'/proba_pro_medium-webfont.eot?#iefix') format('embedded-opentype'),
    url($pik-fonts-path+'/proba_pro_medium-webfont.woff2') format('woff2'),
    url($pik-fonts-path+'/proba_pro_medium-webfont.woff') format('woff'),
    url($pik-fonts-path+'/proba_pro_medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-display: fallback;
}
@font-face {
  font-family: ProbaPro;
  src: url($pik-fonts-path+'/proba_pro_semibold-webfont.eot');
  src: url($pik-fonts-path+'/proba_pro_semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url($pik-fonts-path+'/proba_pro_semibold-webfont.woff2') format('woff2'),
    url($pik-fonts-path+'/proba_pro_semibold-webfont.woff') format('woff'),
    url($pik-fonts-path+'/proba_pro_semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-display: fallback;
}
