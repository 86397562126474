h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

h1 {
  font-size: 2rem;
  margin: 1rem 0;
}
h5, h4 {
  font-size: 1rem;
  margin: 1rem 0;
}

.link {
  color: $blue;
  text-decoration: none;
  cursor: pointer;
}
