/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~flatpickr/dist/themes/airbnb.css";

@import 'assets/styles/normalize';
@import 'assets/styles/variables';
@import 'assets/styles/fonts';
@import 'assets/styles/grid';
@import 'assets/styles/global';
@import 'assets/styles/common/index';
