.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -.75rem;
  .col {
    padding: 0 .75rem;
    &.col-12, &.col-md-12 {
      flex: 0 0 100%;
    }
    &.col-8, &.col-md-8 {
      flex: 0 0 66.6%;
    }
    &.col-6, &.col-md-6 {
      flex: 0 0 50%;
    }
    &.col-4, &.col-md-4 {
      flex: 0 0 33.3%;
    }
    &.col-3, &.col-md-3 {
      flex: 0 0 25%;
    }
    &.col-md-6,
    &.col-md-4,
    &.col-md-3 {
      @include respond-to(mobiles) {
        flex: 0 0 100%;
      }
    }
  }
}
